<template>
  <div class="container d-flex justify-content-center align-items-center">
   
    <div class="card shadow card-form mb-4" v-motion-slide-visible-bottom>
      <div class="success-email" v-if="sentEmail">
      <p class="text-center p-3">Success! We shall reach out in a few minutes</p>
    </div>
      <form class="form" @submit.prevent="sendEmail" v-if="notSent">
        <p class="title">Get Started</p>
        <p class="message">Signup now and get a free account</p>
        <div class="flex">
          <label>
            <input
              required=""
              placeholder=""
              type="text"
              class="input"
              v-model="name"
              id="name"
              name="name"
            />
            <span>Name</span>
          </label>

          <label>
            <input
              required=""
              placeholder=""
              type="tel"
              class="input"
              v-model="mobile"
              id="mobile"
              name="mobile"
            />
            <span>Mobile</span>
          </label>
          <label class="w-100">
            <input
              required=""
              placeholder=""
              type="email"
              class="input"
              v-model="email"
              id="email"
              name="email"
            />
            <span>Email</span>
          </label>
        </div>

        <label>
          <input
            required=""
            placeholder=""
            type="text"
            class="input"
            v-model="company"
            id="company"
            name="company"
          />
          <span>Company Name</span>
        </label>
        <label>
          <textarea
            required=""
            placeholder="Tell us what you are looking to achieve with the service"
            class="input"
            v-model="message"
            id="message"
            name="message"
          ></textarea>
        </label>
        <button class="submit">Submit</button>

        <p class="signin">
          Already have an account ?
          <a href="https://bulksms.joritechsystems.co.ke/auth/login">Sign In</a>
        </p>
      </form>
    </div>
  </div>
</template>

<script>
import emailjs from "emailjs-com";
export default {
  data() {
    return {
      name: "",
      email: "",
      message: "",
      mobile: "",
      company: "",
      sentEmail: false,
      notSent: true,
    };
  },

  methods: {
    sendEmail(e) {
      const templateParams = {
        name: this.name,
        email: this.email,
        message: this.message,
        mobile: this.mobile,
        company: this.company,
      };

      this.sentEmail = true;
      this.notSent = false;

      emailjs
        .sendForm(
          "service_61qgoi6",
          "template_c2nb9xl",
          e.target,
          "WjadsAQtCGYmXuyf7",
          templateParams
        )
        .then((response) => {
          // Reset form fields or set success flag
          this.name = "";
          this.email = "";
          this.message = "";
          this.phone = "";
          this.company = "";
          return response;
        })
        .catch((error) => {
          console.error("Error sending email:", error);
        });
    },
  },
};
</script>

<style scoped>
.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #fff;
  padding: 20px;
  border-radius: 20px;
  position: relative;
}

.title {
  font-size: 28px;
  color: black;
  font-weight: 600;
  letter-spacing: -1px;
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 30px;
}

.title::before,
.title::after {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  border-radius: 50%;
  left: 0px;
  background-color: #8f9779;
}

.title::before {
  width: 18px;
  height: 18px;
  background-color: #8f9779;
}

.title::after {
  width: 18px;
  height: 18px;
  animation: pulse 1s linear infinite;
}

.message,
.signin {
  color: rgba(88, 87, 87, 0.822);
  font-size: 14px;
}

.signin {
  text-align: center;
}

.signin a {
  color: royalblue;
}

.signin a:hover {
  text-decoration: underline royalblue;
}

.flex {
  display: flex;
  width: 100%;
  gap: 6px;
}

.form label {
  position: relative;
}

.form label .input {
  width: 100%;
  padding: 10px 10px 20px 10px;
  outline: 0;
  border: 1px solid rgba(105, 105, 105, 0.397);
  border-radius: 10px;
}

.form label .input + span {
  position: absolute;
  left: 10px;
  top: 15px;
  color: grey;
  font-size: 0.9em;
  cursor: text;
  transition: 0.3s ease;
}

.form label .input:placeholder-shown + span {
  top: 15px;
  font-size: 0.9em;
}

.form label .input:focus + span,
.form label .input:valid + span {
  top: 30px;
  font-size: 0.7em;
  font-weight: 600;
}

.form label .input:valid + span {
  color: green;
}

.submit {
  border: none;
  outline: none;
  background-color: #8f9779;
  padding: 10px;
  border-radius: 10px;
  color: #fff;
  font-size: 16px;
  transform: 0.3s ease;
  transition: transform 0.3s ease-in-out;
}

@keyframes pulse {
  from {
    transform: scale(0.9);
    opacity: 1;
  }

  to {
    transform: scale(1.8);
    opacity: 0;
  }
}
</style>
