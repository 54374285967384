<template>
  <carousel
    :items-to-show="3"
    class="custom-carousel"
    :wrap-around="true"
    :autoplay="3000"
  >
    <slide v-for="image in images" :key="image" class="custom-slide">
      <img
        :src="require(`@/assets/img/${image}`)"
        alt="Slide {{ image }}"
        class="carousel-image mx-3 p-2 img-fluid"
        width="150"
      />
    </slide>

    <template #addons>
      <pagination class="mt-5" />
    </template>
  </carousel>
</template>
<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination } from "vue3-carousel";

export default {
  name: "ClientList",
  components: {
    Carousel,
    Slide,
    Pagination,
  },
  data() {
    return {
      images: ["githu.png", "dama.png", "kiambu.jpeg", "afro.png","minalex.jpg"],
    };
  },
};
</script>

<style scoped>
.carousel-image {
 
}
</style>
