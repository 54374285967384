<template>
  <div class="content">
    <nav class="navbar navbar-expand-lg fixed-top" id="navigation">
      <div class="container">
        <a class="navbar-brand" href="#"
          ><img
            src="@/assets/img/joritech.png"
            width=""
            class=""
            id="nav-brand"
          />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div
          class="collapse navbar-collapse justify-content-end"
          id="navbarNav"
        >
          <ul class="navbar-nav">
            <li class="nav-item active">
              <a href="#hero" class="nav-link">Home</a>
            </li>
            <li class="nav-item">
              <a href="#sms" class="nav-link">Bulk SMS</a>
            </li>
            <li class="nav-item">
              <a href="#others" class="nav-link">Other Services</a>
            </li>
            <li class="nav-item">
              <a href="#started" class="nav-link">Get Started</a>
            </li>
            <li class="nav-item">
              <a
                href="https://bulksms.joritechsystems.co.ke/auth/login"
                class="nav-link"
                >Login</a
              >
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <div class="custom-bg" id="hero">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-6 mb-4 mb-md-0">
            <div class="hero-section container">
              <div>
                <h1 class="">Instant Bulk SMS Solutions for Your Business!</h1>
                <p class="mt-5 bigger-text">
                  Effortlessly reach your goals with Jori Tech Systems. From
                  innovative Bulk SMS, USSD and shortcode solutions to
                  cutting-edge software development services, we provide
                  tailored solutions to streamline your business operations.Our
                  services ensure seamless communication and efficient
                  workflows.
                </p>
              </div>

              <div class="mt-5">
                <customCtaBtn @click="scrollToContact" />
              </div>
            </div>
          </div>
          <div
            class="col-md-6 d-flex justify-content-center align-items-center"
          >
            <div>
              <img
                src="@/assets/img/smsman.png"
                alt="Man"
                class="mt-5 responsive-img"
                width="800"
                v-motion-roll-visible-bottom
              />
            </div>
          </div>
        </div>
      </div>

      <div class="svg-wave">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#8f9779"
            fill-opacity="1"
            d="M0,256L60,240C120,224,240,192,360,186.7C480,181,600,203,720,192C840,181,960,139,1080,101.3C1200,64,1320,32,1380,16L1440,0L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
          ></path>
        </svg>
      </div>
    </div>

    <div class="services-section">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <ul
              class="bigger-text text-white list-unstyled mt-5 mx-5 row row-cols-1 row-cols-md-2"
            >
              <li class="col mb-5">
                <div class="row align-items-center">
                  <div class="col-auto">
                    <div
                      class="d-flex justify-content-center align-items-center"
                      v-motion-roll-visible-bottom
                    >
                      <img
                        src="@/assets/img/group.png"
                        alt="Group icon"
                        class="img-fluid mb-4 services-logo"
                        width="150"
                      />
                    </div>
                    <p class="text-bold text-center">100% Bulk Delivery</p>
                  </div>
                </div>
              </li>
              <li class="col mb-5">
                <div class="row align-items-center">
                  <div class="col-auto">
                    <div
                      class="d-flex justify-content-center align-items-center"
                      v-motion-roll-visible-bottom
                    >
                      <img
                        src="@/assets/img/target.png"
                        alt="Target icon"
                        class="img-fluid mb-4 services-logo"
                        width="150"
                      />
                    </div>
                    <p class="text-bold text-center">Targeted SMS</p>
                  </div>
                </div>
              </li>
              <li class="col mb-5">
                <div class="row align-items-center">
                  <div class="col-auto">
                    <div
                      class="d-flex justify-content-center align-items-center"
                      v-motion-roll-visible-bottom
                    >
                      <img
                        src="@/assets/img/save.png"
                        alt="Save icon"
                        class="img-fluid mb-4 services-logo"
                        width="150"
                      />
                    </div>
                    <p class="text-bold text-center">Cost Effective</p>
                  </div>
                </div>
              </li>
              <li class="col">
                <div class="row align-items-center">
                  <div class="col-auto">
                    <div
                      class="d-flex justify-content-center align-items-center"
                      v-motion-roll-visible-bottom
                    >
                      <img
                        src="@/assets/img/rocket.png"
                        alt="Rocket icon"
                        class="img-fluid mb-4 services-logo"
                        width="150"
                      />
                    </div>
                    <p class="text-bold text-center">Fast Delivery</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="col-lg-6" id="sms">
            <div class="affordable-container">
              <h1 class="text-center">Affordable Bulk SMS</h1>
              <p class="mt-4 bigger-text text-white">
                Our web-based SMS platform offers a reliable and intuitive
                solution for your SMS needs. Whether you're managing
                communications from your office or on the move, our
                user-friendly interface ensures smooth operation. Seamlessly
                integrate SMS into your existing systems with our robust APIs,
                empowering your business with efficient communication tools.
                Trust us to handle your updates, promotions, reminders, and
                urgent alerts with ease and reliability
              </p>

              <p class="bigger-text text-white">
                Discover cost-effective Bulk SMS solutions customized to suit
                your requirements. Our platform equips businesses spanning
                retail, education, healthcare and beyond to effortlessly engage
                their target audience. Harness the potential of Bulk SMS to
                deliver personalized messages to expansive groups, enhancing
                engagement and driving conversions. Embrace the transformative
                capabilities of Bulk SMS and open up fresh opportunities for
                your business today
              </p>

              <div class="container">
                <div class="row">
                  <div class="col-md-6">
                    <div
                      class="mt-5 mb-4 d-flex justify-content-center align-items-center"
                    >
                      <img
                        src="@/assets/img/imessage.png"
                        class="img-fluid rounded screen-pic"
                        alt="Bulk SMS"
                        width="400"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div
                      class="mt-5 mb-4 d-flex justify-content-center align-items-center"
                    >
                      <img
                        src="@/assets/img/phonetwo.png"
                        class="img-fluid rounded screen-pic"
                        alt="Bulk SMS"
                        width="400"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="lower-svg">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#8f9779"
          fill-opacity="1"
          d="M0,64L48,90.7C96,117,192,171,288,186.7C384,203,480,181,576,149.3C672,117,768,75,864,96C960,117,1056,203,1152,229.3C1248,256,1344,224,1392,208L1440,192L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
        ></path>
      </svg>
    </div>

    <div class="services" id="services">
      <div class="container mb-5" id="others">
        <h1 class="text-center mb-5">More Solutions</h1>
        <div class="row other-services">
          <!-- USSD Container -->
          <div class="col-lg-4 col-md-6 mb-4" v-motion-slide-visible-bottom>
            <div class="card shadow">
              <div class="card-body">
                <div
                  class="img-container d-flex justify-content-center align-items-center"
                >
                  <img
                    src="@/assets/img/ussd.png"
                    class="img-fluid mb-3 services-logo"
                    alt="USSD Logo"
                    width="200"
                  />
                </div>

                <!-- Title and Content for USSD -->
                <h5 class="card-title text-center mt-3">USSD</h5>
                <p class="card-text text-center">
                  Implement interactive menus and real-time responses with our
                  USSD solutions.
                </p>
                <button
                  class="btn btn-outline w-100 mt-3"
                  @click="scrollToContact"
                >
                  Get Quote
                </button>
              </div>
            </div>
          </div>

          <!-- SMS Shortcode Container -->
          <div class="col-lg-4 col-md-6 mb-4" v-motion-slide-visible-bottom>
            <div class="card shadow">
              <div class="card-body">
                <div
                  class="img-container d-flex justify-content-center align-items-center"
                >
                  <img
                    src="@/assets/img/chat.png"
                    class="img-fluid mb-3 services-logo"
                    alt="USSD Logo"
                    width="200"
                  />
                </div>
                <h5 class="card-title text-center mt-3">SMS Shortcodes</h5>
                <p class="card-text text-center">
                  Two-way messaging for effective communication & marketing
                  campaigns.
                </p>
                <button
                  class="btn btn-outline w-100 mt-3"
                  @click="scrollToContact"
                >
                  Get Quote
                </button>
              </div>
            </div>
          </div>

          <!-- SMS APIs Container -->
          <div class="col-lg-4 col-md-6 mb-4" v-motion-slide-visible-bottom>
            <div class="card shadow">
              <div class="card-body">
                <div
                  class="img-container d-flex justify-content-center align-items-center"
                >
                  <img
                    src="@/assets/img/api.png"
                    class="img-fluid mb-3 services-logo"
                    alt="USSD Logo"
                    width="200"
                  />
                </div>
                <h5 class="card-title text-center mt-3">SMS APIs</h5>
                <p class="card-text text-center">
                  Integrate SMS functionality into your applications using our
                  simple to use APIs.
                </p>
                <button
                  class="btn btn-outline w-100 mt-3"
                  @click="scrollToContact"
                >
                  Get Quote
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="more-services-section">
      <div class="container">
        <div class="row no-gutters">
          <div
            class="col-md-6 d-flex justify-content-center align-items-center"
          >
            <img
              src="@/assets/img/woman.png"
              alt=""
              class="img-fluid mt-5 woman-pic"
              width="900"
              v-motion-roll-visible-bottom
            />
          </div>

          <div class="col-md-6">
            <div class="container choose-section">
              <h1 class="section-heading">Why Choose Us?</h1>
              <p class="bigger-text mt-5 text-white">
                Our commitment to affordability, flexible payment terms, swift
                delivery and user-friendly APIs makes us the ideal partner for
                your business. Experience the difference and unlock unparalleled
                value, adaptability, speed and simplicity tailored to your
                business needs. Join our esteemed clientele.
              </p>

              <div class="container">
                <ClientList class="mt-5 mb-5" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="account mb-5" id="started">
      <h1 class="text-center p-2">Get a free account in one click</h1>
      <div class="container-fluid mt-5">
        <div class="row no-gutters">
          <div class="col-md-12">
            <signUpForm class="" />
          </div>
        </div>
      </div>
    </div>

    <div class="footer-section" id="contact">
      <footer class="container">
        <div class="row justify-content-between align-items-center">
          <!-- Contact Information -->
          <div class="col-lg-4 mt-5">
            <h3 class="text-center">Talk To Us</h3>
            <p class="text-white text-center footer-text">
              Mobile: +254 713101945
            </p>
            <p class="text-white text-center">
              Email: joritechsystems@gmail.com
            </p>
          </div>

          <!-- Location Information -->
          <div class="col-lg-4 text-center mt-5 mb-3">
            <div>
              <h3 class="text-center">
                <font-awesome-icon
                  icon="fa-solid fa-location-dot"
                  class="mx-2 location-icon"
                ></font-awesome-icon
                >Location
              </h3>

              <p class="text-white footer-text">
                Munyu Road Business Center - Munyu Road, Off Luthuli
              </p>
              <p class="text-white">3rd Floor, Room 3D520</p>
            </div>
          </div>
          <!-- Social Media Icons -->
          <div class="col-lg-4 text-center">
            <div class="">
              <h3 class="text-center">Socials</h3>

              <div class="social-icons footer-text">
                <a
                  data-mdb-ripple-init
                  class="btn text-white btn-floating m-1"
                  style="background-color: #3b5998"
                  href="https://www.facebook.com/people/bulksms_joritechsystems/100088849294626/?mibextid=ZbWKwL!"
                  role="button"
                >
                  <font-awesome-icon
                    icon="fa-brands fa-facebook"
                    class="mx-2"
                  ></font-awesome-icon>
                </a>

                <a
                  data-mdb-ripple-init
                  class="btn text-white btn-floating m-1"
                  style="background-color: #55acee"
                  href="#"
                  role="button"
                >
                  <font-awesome-icon
                    icon="fa-brands fa-twitter"
                    class="mx-2"
                  ></font-awesome-icon>
                </a>

                <a
                  data-mdb-ripple-init
                  class="btn text-white btn-floating m-1"
                  style="background-color: #ac2bac"
                  href="https://www.instagram.com/bulksms_joritechsystems"
                  role="button"
                >
                  <font-awesome-icon
                    icon="fa-brands fa-instagram"
                    class="mx-2"
                  ></font-awesome-icon>
                </a>

                <a
                  data-mdb-ripple-init
                  class="btn text-white btn-floating m-1"
                  style="background-color: #25d366"
                  href="https://api.whatsapp.com/send?phone=254719328776&text=Hi%20I%20need%20your%20sms%20services"
                  role="button"
                >
                  <font-awesome-icon
                    icon="fa-brands fa-whatsapp"
                    class="mx-2"
                  ></font-awesome-icon>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="text-center p-3">
          <p class="text-center mt-2 text-white">
            © {{ currentYear }} Copyright: Jori Tech Systems
          </p>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import customCtaBtn from "@/components/CustomCtaButton";
import signUpForm from "@/components/SignupForm";
import ClientList from "@/components/ClientList";

export default {
  components: {
    customCtaBtn,
    signUpForm,
    ClientList,
  },

  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },

  methods: {
    scrollToContact() {
      const contactSection = document.getElementById("started");
      contactSection.scrollIntoView({ behavior: "smooth" });
    },

    setupNavLinks() {
      const navLinks = document.querySelectorAll(".nav-link");
      navLinks.forEach((navLink) => {
        navLink.addEventListener("click", () => {
          const navbar = document.querySelector(".navbar-collapse");
          const isCollapsed = navbar.classList.contains("show");
          if (isCollapsed) {
            const button = document.querySelector(".navbar-toggler");
            button.click();
          }
        });
      });
    },
  },

  mounted() {
    this.setupNavLinks();

    updateNavigationStyles();

    window.scrollTo(0, 0);

    window.addEventListener("scroll", function () {
      updateNavigationStyles();
    });

    function updateNavigationStyles() {
      if (window.innerWidth > 768) {
        // Check if screen size is larger than 768px (small screens)
        if (
          document.body.scrollTop > 80 ||
          document.documentElement.scrollTop > 80
        ) {
          document.getElementById("navigation").style.background =
            "rgb(143, 151, 121)";
         
        } else {
          document.getElementById("navigation").style.background = "";
          
        }
      } else {
        // For small screens, maintain constant background
        document.getElementById("navigation").style.background =
          "rgb(143, 151, 121)";
        document.getElementById("nav-brand").style.width = "140px";
      }
    }
  },
};
</script>

<style></style>
